import React, { FC } from "react";
import styled from "styled-components";

const LogoX1 = require("../../assets/Logo@1x.png");
const LogoX2 = require("../../assets/Logo@2x.png");

const LogoPicture = styled.picture<{ height: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ height }) => height}px;
    box-sizing: border-box;
    cursor: pointer;
    * {
        height: 100%;
    }
`;
export interface LogoProps {
    height?: number;
}

const Logo: FC<LogoProps> = ({ height = 80 }) => {
    return (
        <LogoPicture height={height}>
            <source srcSet={LogoX2} />
            <img src={LogoX1} alt="Logo" />
        </LogoPicture>
    );
};

export default Logo;
