import React, { cloneElement, isValidElement } from "react";
import styled from "styled-components";
import BodySession, { TitleBody } from "../../components/BodySession";
import { colors } from "../../theme/colors";

import { ReactComponent as CoinIcon } from "../../assets/Coin.svg";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";
import { ReactComponent as Niqueis } from "../../assets/Niqueis.svg";
import { ReactComponent as People } from "../../assets/People.svg";
// import SetaButton from "../../components/Seta";

const Card = styled.div`
    width: 342px;
    height: 500px;
    padding: 46px 0; // Adicione o padding desejado aqui
    user-select: none;

    box-sizing: border-box; // Isso faz com que o padding seja incluído no tamanho do elemento

    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    background-color: ${({ theme }) => theme.colors.bg};
    box-shadow: ${({ theme }) => `10px 30px 70px 0px ${theme.colors.cardShadow}66`};

    &:hover {
        cursor: pointer;
        transform: scale(1.02);
        transition: 0.2s ease-in-out;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.cardSelected};
    }
`;

const Icon = styled.div<{ smal?: boolean; color?: string }>`
    width: ${({ smal }) => (!!smal ? "45px" : "97px")};
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: ${({
        color,
        theme: {
            colors: { cardButton },
        },
    }) => (color ? color : cardButton)};
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: ${({ theme, smal, color }) => !smal && `0px 15px 30px 0px ${color ? color : theme.colors.cardShadow}88`};

    ${Card}:hover & {
        cursor: pointer;
        transform: scale(1.02);
        transition: 0.2s ease-in-out;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: ${({ theme, smal }) => !smal && `0px 15px 30px 0px ${theme.colors.cardSelectedShadow}`};
        path {
            fill: ${({ color }) => color};
        }
    }
`;

// const SetaButtonIcon = styled(SetaButton)`
//     ${Card}:hover & {
//         cursor: pointer;
//         transform: scale(1.02);
//         transition: 0.2s ease-in-out;
//         background-color: ${({ theme }) => theme.colors.white} !important;
//     }
// `;

const SessionCards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    text-align: start;
    width: 100%;
    gap: 40px;
`;

const CardTitle = styled.h3`
    font-size: 20px;
    margin: 0;
    margin-bottom: 0.5em;
`;

const SessionCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 292px;

    p {
        font-size: 18px;
        text-align: center;
    }
`;

interface CardProps {
    title: string;
    text: string;
    color: string;
    iconSvg?: any;
}

const CardOurService = ({ title, text, color, iconSvg }: CardProps) => {
    return (
        <Card>
            <Icon color={color}>{isValidElement(iconSvg) && cloneElement(iconSvg)}</Icon>
            <SessionCard>
                <CardTitle>{title}</CardTitle>
                <p>{text}</p>
            </SessionCard>
            {/* <SetaButtonIcon /> */}
        </Card>
    );
};

const DataCars = [
    {
        title: "Segurança",
        text: "A Yallo proporciona segurança e tranquilidade para seus parceiros de receber todas as suas comissões e pagamentos.",
        color: colors.main.primary,
        iconSvg: <CoinIcon />,
    },
    {
        title: "Oportunidade",
        text: "Quem conhece a Yallo não larga mais. Aqui teremos várias oportunidades para você!",
        color: colors.third.red,
        iconSvg: <Niqueis />,
    },
    {
        title: "Suporte brasileiro",
        text: "Hoje contamos com um time de colaboradores 100% dedicados para te apresentar o melhor suporte, seja ele em inglês, mandarim, e português.",
        color: colors.third.blue,
        iconSvg: <People />,
    },
    {
        title: "Eventos",
        text: "Realizamos vários tipos de eventos como: Patrocínio de festas, Festas de lançamento de plataformas, ranking de influencers com sorteios...",
        color: colors.second.yellow,
        iconSvg: <Calendar />,
    },
];

const OurService = () => {
    return (
        <BodySession>
            <>
                <TitleBody>Quais os benefícios da Yallo?</TitleBody>
                <SessionCards>
                    {DataCars.map((item, index) => (
                        <CardOurService key={index} text={item.text} title={item.title} color={item.color} iconSvg={item.iconSvg} />
                    ))}
                </SessionCards>
            </>
        </BodySession>
    );
};

export default OurService;
