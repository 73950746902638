import React, { FC } from "react";
import styled from "styled-components";

const ArrowIcon = require("../../assets/Arrow Right.png");

const Icon = styled.div<{ smal?: boolean; color?: string; invert?: boolean }>`
    width: ${({ smal }) => (!!smal ? "45px" : "97px")};
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: ${({
        color,
        theme: {
            colors: { cardButton },
        },
    }) => (color ? color : cardButton)};
    display: flex;
    align-items: center;
    justify-content: center;

    user-select: none;

    // shadow color FE9602, x 0 , y 15 , blur 30

    box-shadow: ${({ theme, smal, color }) => !smal && `0px 15px 30px 0px ${color ? color : theme.colors.cardShadow}88`};

    &:hover {
        cursor: pointer;
        transform: scale(1.02);
        transition: 0.2s ease-in-out;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: ${({ theme, smal }) => !smal && `0px 15px 30px 0px ${theme.colors.cardSelectedShadow}`};
        path {
            fill: ${({ color }) => color};
        }
    }

    img {
        transform: ${({ invert }) => (!!invert ? "rotate(180deg)" : "rotate(0deg)")};
        width: 21px !important;
    }
`;
export interface SetaButtonProps {
    invert?: boolean;
    onClick?: () => void;
}

const SetaButton: FC<SetaButtonProps> = ({ invert = false, onClick }) => {
    return (
        <>
            <Icon smal={true} invert={invert} onClick={onClick}>
                <img src={ArrowIcon} alt="Arrow" />
            </Icon>
        </>
    );
};

export default SetaButton;
