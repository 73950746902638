import styled, { css } from "styled-components";

export const TopCardSession = styled.div`
    margin-top: 70px;
    margin-bottom: 16px;
    height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    background-color: ${({ theme }) => theme.colors.cardButton};
    border-radius: 28px;
    width: clamp(100px, 85%, 1400px);

    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);


    @media screen and (max-width: 768px) {
        padding: 0 20px;
        p {
            margin: 0;
            font-size: 14px;
        }
    }
`;

export const SessionCards = styled.div`
    display: flex;
    gap: 45px;
    width: 100%;
    /* background-color: ${({ theme }) => theme.colors.cardButton}; */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 90px;
    margin-top: 40px;

    @media screen and (max-width: 1355px) {
        flex-direction: column;
    }
`;

export const Card = styled.div<{ large?: boolean }>`
    width: clamp(200px, 90%, 391px);
    height: 522px;
    background-color: ${({ theme }) => theme.colors.cardButton};
    /* overflow: hidden; */
    border-radius: 20px;
    margin-bottom: 8px;
    user-select: none;
    position: relative;
    
    ${({ large }) => large && css`
        transform: scale(1.1);
    `}

    p {
        margin: 0;
        text-align: start;
        @media screen and (max-width: 768px) {
            font-size: 14px;
        }
    }
    transition: background-color 0.3s ease;
    /* &:hover {
        background-color: ${({ theme }) => theme.colors.cardSelected};
    } */

    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.4);
`;

export const CardHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 96px;
    width: 100%;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.primary};
`;

export const TitleCard = styled.h4`
    font-size: 54px;
`;

export const BronzeGradient = css`
    background-image: linear-gradient(90deg, #be7b56 0%, #de964c 50%, #bc7051 100%);
    background: linear-gradient(90deg, #be7b56 0%, #de964c 50%, #bc7051 100%);
`;

export const TitleCardBronze = styled(TitleCard)`
    // gradient linear BE7B56, DE964C, BC7051
    color: #de964c;
    /* gradient text for modern browsers */
    @supports (background-clip: text) {
        ${BronzeGradient}
        background-clip: text;
        color: transparent;
    }
`;

export const TitleCardOuro = styled(TitleCard)`
    // gradient linear BE7B56, DE964C, BC7051
    color: #feaf02;
`;

export const PrataGradient = css`
    background-image: linear-gradient(90deg, #c4c9cd 0%, #f0f0f2 50%, #b3b4b9 100%);
    background: linear-gradient(90deg, #c4c9cd 0%, #f0f0f2 50%, #b3b4b9 100%);
`;

export const TitleCardPrata = styled(TitleCard)`
    ${TitleCard}
    // gradient linear BE7B56, DE964C, BC7051
    color: #c4c9cd;
    /* gradient text for modern browsers */
    @supports (background-clip: text) {
        ${PrataGradient}
        background-clip: text;
        color: transparent;
    }
`;

export const CardFooterMark = styled.div`
    width: 100%;
    height: 34px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #feaf02;
    border-radius: 0 0 20px 20px;
`;
export const CardFooterMarkBronze = styled(CardFooterMark)`
    ${BronzeGradient}
`;
export const CardFooterMarkPrata = styled(CardFooterMark)`
    ${PrataGradient}
`;
