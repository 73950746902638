import { createBrowserRouter } from "react-router-dom";
import DefaultRouter from "./defaultRouter";
import HomePage from "./home";
import SobrePage from "./sobre";
import ContatoPage from "./Contato";
import ParcPage from "./parceiro";

const router = createBrowserRouter([
    {
        path: "/",
        element: <DefaultRouter />,
        children: [
            {
                path: "",
                element: <HomePage />,
            },
            {
                path: "partners",
                element: <ParcPage />,
            },
            {
                path: "Galeria",
                element: <HomePage />,
            },
            {
                path: "about",
                element: <SobrePage />,
            },
            {
                path: "contact",
                element: <ContatoPage />,
            },
        ],
    },
]);

export default router;
