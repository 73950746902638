import React, { FC } from "react";
import Modal from "../../components/Modal";
import styled from "styled-components";

const BodyContainer = styled.div`
    height: calc(100% - 40px);
    padding: 20px;
    display: flex;
    @media screen and (max-width: 600px) {
        padding: 20px 10px;
        width: calc(100% - 20px);
    }
`;

const ScroolWrapper = styled.div`
    height: calc(100% - 40px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    text-align: justify;
    gap: 20px;
    padding: 20px;
    h2 {
        font-size: 24px;
        margin: 0;
        margin-top: 20px;
    }
    p {
        font-size: 19px;
        margin: 0;
    }

    @media screen and (max-width: 600px) {
        text-align: start;
        padding: 5px;
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 19px;
        }
        p {
            text-align: justify;
            font-size: 14px;
        }
    }
`;

export interface ContratoModalProps {
    open?: boolean;
    onClose?: () => void;
}

const ContratoModal: FC<ContratoModalProps> = ({ open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <BodyContainer>
                <ScroolWrapper>
                    <h1>1. Política de Privacidade</h1>
                    <p>
                        A YALLO Ltda. está comprometida em proteger a privacidade dos visitantes do nosso site. Esta política de privacidade descreve como
                        coletamos, usamos e protegemos as informações pessoais que você nos fornece. Informações Coletadas
                    </p>
                    <h2>2. Informações Coletadas</h2>
                    <p>
                        Ao preencher o formulário de inscrição em nosso site, você nos fornece informações pessoais, como nome, endereço de e-mail e informações
                        de contato. Todas essas informações serão visíveis na seção "Contato" do nosso site.
                    </p>
                    <h2>3. Uso das Informações</h2>
                    <p>
                        As informações coletadas são usadas exclusivamente para análise interna. Se considerarmos adequado, entraremos em contato com você para
                        discutir oportunidades de colaboração.
                    </p>
                    <h2>4. Compartilhamento de Informações</h2>
                    <p>
                        Não compartilhamos suas informações pessoais com terceiros externos à YALLO Ltda. Todas as informações fornecidas são compartilhadas
                        internamente apenas com o propósito de avaliação e potencial colaboração.
                    </p>
                    <h2>5. Acesso e Atualização de Informações</h2>
                    <p>
                        Se você deseja atualizar ou corrigir suas informações pessoais, entre em contato conosco usando os detalhes fornecidos na seção
                        "Contato" do nosso site.
                    </p>
                    <h2>6. Medidas de Segurança</h2>
                    <p>Nossos dados são armazenados e protegidos conforme as medidas de segurança fornecidas pelo Google Cloud.</p>
                    <h2>7. Cookies</h2>
                    <p>Não utilizamos cookies ou tecnologias similares em nosso site.</p>
                    <h2>8. Menores de Idade</h2>
                    <p>Nosso site não é direcionado a menores de idade, e não coletamos intencionalmente informações de crianças menores de 18 anos.</p>
                    <h2>9. Contato</h2>
                    <p>
                        Se você tiver dúvidas ou preocupações sobre esta política de privacidade, entre em contato conosco através dos detalhes fornecidos na
                        seção "Contato" do nosso site.
                    </p>
                    <h2>10. Alterações na Política de Privacidade</h2>
                    <p>
                        Reservamo-nos o direito de fazer alterações nesta política de privacidade a qualquer momento. As alterações serão publicadas nesta
                        página.
                    </p>
                    <p>Ao utilizar nosso site, você concorda com os termos desta política de privacidade.</p>
                    <p>
                        Última atualização: <span style={{ fontWeight: 700 }}>10/04/2024</span>
                    </p>
                </ScroolWrapper>
            </BodyContainer>
        </Modal>
    );
};

export default ContratoModal;
