import React, { FC, useCallback, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as MenuIcon } from "../../assets/Menu.svg";
import { NavBarProps, NavBarDIV, Menu, NavBarMobleSession, DivLinks, NavBarTitle } from "./styled";
import Logo from "../Logo";
import Buttom from "../Button";

const NavBar: FC<NavBarProps> = () => {
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const [open, setOpen] = React.useState(true);

    useEffect(() => {
        setOpen(false);
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (ref.current && event.target && !ref.current.contains((event as any).target)) {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <NavBarDIV>
            <div
                onClick={() => {
                    navigate("/");
                }}
            >
                <Logo />
            </div>
            <Menu>
                <MenuIcon
                    onClick={() => {
                        setOpen((old) => !old);
                    }}
                    style={{ cursor: "pointer" }}
                />
            </Menu>
            <NavBarMobleSession open={open} ref={ref}>
                <DivLinks>
                    <NavBarTitle
                        selected={location.pathname === "/"}
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <h6>Pagina Inicial</h6>
                    </NavBarTitle>
                    <NavBarTitle
                        selected={location.pathname === "/partners"}
                        onClick={() => {
                            navigate("/partners");
                        }}
                    >
                        <h6>Parceiros</h6>
                    </NavBarTitle>
                    <NavBarTitle
                        selected={location.pathname === "/about"}
                        onClick={() => {
                            navigate("/about");
                        }}
                    >
                        <h6>Sobre</h6>
                    </NavBarTitle>
                </DivLinks>
                <Buttom onClick={() => navigate("/contact")}>
                    <p style={{ fontSize: "18px", fontWeight: 600, color: "#fff", padding: "0 20px" }}>Contato</p>
                </Buttom>
            </NavBarMobleSession>
        </NavBarDIV>
    );
};

export default NavBar;
