import React, { FC } from "react";
import BodySession from "../../components/BodySession";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "react-responsive-carousel/lib/styles"

import { ReactComponent as Quotes } from "../../assets/Quotes.svg";
import SetaButton from "../../components/Seta";

const QuotesBG = styled.div`
    width: 100vw;
    height: 507px;
    background-color: ${({ theme }) => theme.colors.quotesBG};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .carousel {
        width: 80vw !important;
        display: flex;
        align-items: center;

        @media screen and (max-width: 700px) {
            width: 90vw !important;
        }
    }
`;

const QuotesSession = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    p {
        height: 140px;
        max-width: 680px;
        font-size: 28px;
        line-height: 1.5;
        color: ${({ theme }) => theme.colors.white};
        margin-top: 20px;

        @media screen and (max-width: 700px) {
            font-size: 22px;
        }
        @media screen and (max-width: 500px) {
            font-size: 19px;
        }
    }

    span {
        font-size: 20px;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.white};
        margin: 0px;
        padding: 0px;

        @media screen and (max-width: 700px) {
            font-size: 18px;
        }
        @media screen and (max-width: 500px) {
            font-size: 16px;
        }
    }
`;

const datas = [
    {
        text: "Quando a parceria é um sucesso, os dois lados ganham. Isso cresce de forma exponêncial.",
        name: "Ricardo Lin",
        cargo: "Chefe & CEO",
    },
    {
        text: "Em um mercado tão delicado.  O nosso objetivo é tornar as parcerias mais seguras e com um suporte de qualidade",
        name: "Guilherme de lima",
        cargo: "Presidente & COO",
    },
    {
        text: "O desconforto da mudança não equivale ao prazer da conquista, e juntos podemos conquistar muito.",
        name: "Paulo Macedo",
        cargo: "Presidente & CIO",
    },
];

export interface HomeQuotesProps {}

const HomeQuotes: FC<HomeQuotesProps> = () => {
    return (
        <BodySession>
            <QuotesBG>
                <Carousel
                    autoPlay
                    interval={5000}
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                    infiniteLoop
                    renderArrowNext={(onClickHandler, hasNext, label) => <SetaButton onClick={onClickHandler} />}
                    renderArrowPrev={(onClickHandler, hasPrev, label) => <SetaButton invert onClick={onClickHandler} />}
                >
                    {datas.map((data, index) => (
                        <QuotesSession key={index}>
                            <Quotes />
                            <p>{data.text}</p>
                            <>
                                <span>{data.name}</span>
                                <span>{data.cargo}</span>
                            </>
                        </QuotesSession>
                    ))}
                </Carousel>
            </QuotesBG>
        </BodySession>
    );
};

export default HomeQuotes;
