import { collection, doc, getFirestore, setDoc } from 'firebase/firestore'

export const collectionContact = collection(getFirestore(), 'contacts');

interface Contact {
    id: string;
    Carimbo_de_data: string;
    Nome: string;
    CPF: string;
    Email: string;
    Telefone: string;
    Data_de_nascimento: string;
    Link: string[];
};

export const createContact = async (contact: Omit<Contact, 'id' | 'Carimbo_de_data'>) => {
    const docRef = await doc(collectionContact);
    const saoPauloTime = new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" });
    await setDoc(docRef, {
        ...contact,
        Carimbo_de_data: saoPauloTime,
    });
    return docRef.id;
}
