import React, { FC } from "react";
import styled from "styled-components";
import Logo from "../components/Logo";
import { useNavigate } from "react-router-dom";

const FoterSession = styled.div`
    position: relative;
    width: 100vw;
    /* background-color: ${({ theme }) => theme.colors.bgFooter}; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const FooterSession = styled.div`
    position: absolute;
    width: 100vw;
    min-height: 340px;
    top: 170px;
    padding-top: 220px;
    background-color: ${({ theme }) => theme.colors.bgFooter};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    z-index: 0;

    color: ${({ theme }) => theme.colors.white};

    div {
        width: clamp(200px, 90%, 1380px);
    }
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
`;

const FooterBody = styled.div`
    display: flex;
    flex-direction: row;
    /* align-items: start; */
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    * {
        font-size: 20px;

        p {
            font-size: 16px;
        }
    }

    h6 {
        font-size: 24px;
        margin: 0px;
        margin-bottom: 20px;
    }
    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* justify-content: flex-start; */
    }

    @media screen and (max-width: 1073px) {
        align-items: flex-start;
        justify-content: flex-start;
        div,
        #map {
            width: 100% !important;
        }
        h6 {
            margin: 30px 0 20px 0;
        }
    }
`;

const Card = styled.div`
    width: clamp(110px, 80%, 1380px);
    height: 340px;
    background-color: ${({ theme }) => theme.colors.cardFooter};
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    color: ${({ theme }) => theme.colors.white};
    z-index: 1;

    * {
        margin: 0px;
    }

    @media screen and (max-width: 1073px) {
        h1 {
            font-size: 26px;
        }
    }
`;

const Buttom = styled.div`
    width: 230px;
    height: 50px;
    background-color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 12px;
    font-size: 16px;
    user-select: none;
    margin-top: 20px;
    padding: 10px;
    box-shadow: 0px 15px 30px 0px ${({ theme }) => theme.colors.cardSelectedShadow}88;
    &:hover {
        cursor: pointer;
        transform: scale(1.02);
        transition: 0.2s ease-in-out;
        box-shadow: 0px 15px 30px 0px ${({ theme }) => theme.colors.cardSelectedShadow};
    }
`;
const FooterButton = styled.a`
    user-select: none;
    &:hover {
        cursor: pointer;
        transform: scale(1.02);
        transition: 0.2s ease-in-out;
        /* box-shadow: 0px 15px 30px 0px ${({ theme }) => theme.colors.cardSelectedShadow}; */
    }
`;

export interface FooterProps {}

const Footer: FC<FooterProps> = () => {
    const navigate = useNavigate();
    return (
        <FoterSession>
            <Card>
                <p>Você está pronto</p>
                <h1>Torne-se um influencer parceiro.</h1>

                <Buttom onClick={() => navigate("/contact")}>
                    Comece agora
                    <img src={require("../assets/arrow-right-white.png")} alt="Grupo Yallo" style={{ width: "20px" }} />
                </Buttom>
            </Card>
            <FooterSession>
                <FooterBody>
                    <div style={{ width: "360px", textAlign: "start" }}>
                        <Logo height={80} />
                        <p>Hoje, contamos com mais de 200 colaboradores, sendo cinco escritórios em Brasil, Filipinas, Hong Kong, Australia e Dubai."</p>
                    </div>
                    <div style={{ width: "auto" }}>
                        <h6>Representamos</h6>
                        <img id="map" src={require("../assets/Maps.png")} alt="Grupo Yallo" style={{ width: "350px", marginBottom: "10px" }} />
                    </div>
                    <div style={{ width: "208px" }}>
                        <h6>Contato</h6>
                        <div>
                            <p style={{ margin: "0px", marginBottom: "10px" }}>São Paulo, SP Brasil</p>
                            <p style={{ margin: "0px", marginBottom: "10px" }}>grupoyallo@gmail.com</p>
                            <p style={{ margin: "0px", marginBottom: "10px" }}>+55 11 95487-4958</p>
                        </div>
                    </div>
                </FooterBody>
                <Line />
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: 18, padding: "10px" }}>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            gap: "20px",
                        }}
                    >
                        <FooterButton onClick={() => navigate("/")}>Pagina inicial</FooterButton>
                        <FooterButton
                            onClick={() => {
                                navigate("/partners");
                            }}
                        >
                            Parceria
                        </FooterButton>
                        <FooterButton
                            onClick={() => {
                                navigate("/about");
                            }}
                        >
                            Sobre
                        </FooterButton>
                    </div>
                    <div style={{ width: 200, display: "flex", flexDirection: "row", gap: "20px" }}>
                        {/* <span>Termos de uso</span>
                        <span>Política de privacidade</span> */}
                    </div>
                </div>
            </FooterSession>
        </FoterSession>
    );
};

export default Footer;
