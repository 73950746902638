import React, { FC } from "react";
import styled from "styled-components";
import BodySession, { TitleBody } from "../../components/BodySession";
import {
    Card,
    CardFooterMark,
    CardFooterMarkBronze,
    CardFooterMarkPrata,
    CardHeader,
    SessionCards,
    TitleCardBronze,
    TitleCardOuro,
    TitleCardPrata,
    TopCardSession,
} from "./style";
import Logo from "../../components/Logo";

const bronzeList = [
    "Acesso a plataforma confiáveis.",
    "Suporte totalmente brasileiro da YALLO",
    "Recomendações de trabalhos e trabalhos recorrentes",
    "pagamento garantido de acordo com o resultado",
];

const ouroList = ["Benefícios do bronze + prata", "Troféu YALLO", "Acesso antecipado a lista de eventos"];

const prataList = [
    "Benefícios do bronze +",
    "Listagem no site",
    "Reputação YALLO",
    "Participação de eventos",
    "Construção de exibição de reputação na comunidade YALLO",
];

const LogoStatic = styled.div`
    position: absolute;
    top: -24px;
`;

const ParceriaList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding-top: 30px;
`;

const ParceiraBody = styled.div<{ line?: boolean }>`
    width: 263px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    border-bottom: ${({ line }) => (line ? "1px solid #d8d8d8" : "none")};

    p {
        width: 100%;
        line-height: 1.8em;
        text-align: center;
        font-size: 18px;
    }
`;

export interface ParcPageProps {}

const ParcPage: FC<ParcPageProps> = () => {
    return (
        <>
            <TopCardSession>
                <TitleBody>Parceria</TitleBody>
                <p>VOCÊ ESTÁ A UM PASSO DE SE TORNAR O NOSSO PARCEIRO!</p>
                <p>AQUI ESTÃO AS NOSSAS MEDALHAS YALLO</p>
            </TopCardSession>
            <BodySession style={{ maxWidth: "1400px" }}>
                <TitleBody>Quais os benefícios da Yallo?</TitleBody>
                <SessionCards>
                    <Card>
                        <CardHeader>
                            <LogoStatic>
                                <Logo height={53} />
                            </LogoStatic>
                            <TitleCardBronze>BRONZE</TitleCardBronze>
                        </CardHeader>
                        <ParceriaList>
                            {bronzeList.map((item, index, arr) => (
                                <ParceiraBody key={index} line={index < arr.length - 1}>
                                    <p>{item}</p>
                                </ParceiraBody>
                            ))}
                        </ParceriaList>
                        <CardFooterMarkBronze />
                    </Card>
                    <Card large>
                        <CardHeader>
                            <LogoStatic>
                                <Logo height={53} />
                            </LogoStatic>
                            <TitleCardOuro>OURO</TitleCardOuro>
                        </CardHeader>
                        <ParceriaList>
                            {ouroList.map((item, index, arr) => (
                                <ParceiraBody key={index} line={index < arr.length - 1}>
                                    <p style={{ fontSize: 27, lineHeight: "1.7em", marginBottom: 16, fontWeight: 600 }}>{item}</p>
                                </ParceiraBody>
                            ))}
                        </ParceriaList>
                        <CardFooterMark />
                    </Card>
                    <Card>
                        <CardHeader>
                            <LogoStatic>
                                <Logo height={53} />
                            </LogoStatic>
                            <TitleCardPrata>PRATA</TitleCardPrata>
                        </CardHeader>
                        <ParceriaList>
                            {prataList.map((item, index, arr) => (
                                <ParceiraBody key={index} line={index < arr.length - 1}>
                                    <p style={{ lineHeight: "1.6em", marginBottom: 20 }}>{item}</p>
                                </ParceiraBody>
                            ))}
                        </ParceriaList>
                        <CardFooterMarkPrata />
                    </Card>
                </SessionCards>
            </BodySession>
        </>
    );
};

export default ParcPage;
